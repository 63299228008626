import React, { useState } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import '../styles/bedMonitoring.css';

// Notifications 컴포넌트
const Notifications = ({ notifications }) => {
  return (
    <div className="notifications-content">
      <ul>
        {notifications.map(notification => (
          <li key={notification.id} className={`notification ${notification.type}`}>
            {notification.message}
          </li>
        ))}
      </ul>
    </div>
  );
};

const BedMonitoring = () => {
  const [selectedWard, setSelectedWard] = useState('all');

  const wardsData = [
    { id: 1, name: '내과병동', totalBeds: 50, occupied: 40, available: 8, cleaning: 2 },
    { id: 2, name: '외과병동', totalBeds: 40, occupied: 35, available: 3, cleaning: 2 },
    { id: 3, name: '소아과병동', totalBeds: 30, occupied: 25, available: 4, cleaning: 1 },
    { id: 4, name: '산부인과병동', totalBeds: 25, occupied: 20, available: 4, cleaning: 1 },
    { id: 5, name: '정형외과병동', totalBeds: 35, occupied: 30, available: 3, cleaning: 2 },
    { id: 6, name: '신경과병동', totalBeds: 30, occupied: 26, available: 2, cleaning: 2 },
    { id: 7, name: '심장내과병동', totalBeds: 40, occupied: 36, available: 3, cleaning: 1 },
    { id: 8, name: '종양내과병동', totalBeds: 45, occupied: 40, available: 3, cleaning: 2 },
  ];

  const bedDetails = [
    { id: 1, wardId: 1, number: '101', status: 'occupied', patient: '김환자', admissionDate: '2023-06-01', expectedDischargeDate: '2023-06-10' },
    { id: 2, wardId: 1, number: '102', status: 'available', patient: null, admissionDate: null, expectedDischargeDate: null },
    { id: 3, wardId: 2, number: '201', status: 'cleaning', patient: null, admissionDate: null, expectedDischargeDate: null },
    { id: 4, wardId: 2, number: '202', status: 'occupied', patient: '이환자', admissionDate: '2023-06-03', expectedDischargeDate: '2023-06-15' },
    { id: 5, wardId: 3, number: '301', status: 'occupied', patient: '박어린이', admissionDate: '2023-06-02', expectedDischargeDate: '2023-06-08' },
    { id: 6, wardId: 3, number: '302', status: 'available', patient: null, admissionDate: null, expectedDischargeDate: null },
    { id: 7, wardId: 4, number: '401', status: 'occupied', patient: '최산모', admissionDate: '2023-06-04', expectedDischargeDate: '2023-06-11' },
    { id: 8, wardId: 4, number: '402', status: 'cleaning', patient: null, admissionDate: null, expectedDischargeDate: null },
    { id: 9, wardId: 5, number: '501', status: 'occupied', patient: '정골절', admissionDate: '2023-06-05', expectedDischargeDate: '2023-06-20' },
    { id: 10, wardId: 5, number: '502', status: 'available', patient: null, admissionDate: null, expectedDischargeDate: null },
    { id: 11, wardId: 6, number: '601', status: 'occupied', patient: '강두통', admissionDate: '2023-06-06', expectedDischargeDate: '2023-06-13' },
    { id: 12, wardId: 6, number: '602', status: 'cleaning', patient: null, admissionDate: null, expectedDischargeDate: null },
    { id: 13, wardId: 7, number: '701', status: 'occupied', patient: '심장환', admissionDate: '2023-06-07', expectedDischargeDate: '2023-06-21' },
    { id: 14, wardId: 7, number: '702', status: 'available', patient: null, admissionDate: null, expectedDischargeDate: null },
    { id: 15, wardId: 8, number: '801', status: 'occupied', patient: '류암환', admissionDate: '2023-06-08', expectedDischargeDate: '2023-06-22' },
    { id: 16, wardId: 8, number: '802', status: 'cleaning', patient: null, admissionDate: null, expectedDischargeDate: null },
  ];

  const notifications = [
    { id: 1, type: 'discharge', message: '101호 퇴원 예정 (30분 후)' },
    { id: 2, type: 'admission', message: '새로운 입원 환자 도착 예정 (1시간 후)' },
    { id: 3, type: 'cleaning', message: '203호 청소 필요' },
    { id: 4, type: 'discharge', message: '502호 퇴원 예정 (1시간 후)' },
    { id: 5, type: 'admission', message: '소아과 신규 입원 예정 (2시간 후)' },
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const getStatusInKorean = (status) => {
    switch(status) {
      case 'occupied':
        return '사용 중';
      case 'available':
        return '사용 가능';
      case 'cleaning':
        return '청소 중';
      default:
        return status;
    }
  };

  const renderWardChart = (ward) => {
    const data = [
      { name: '사용 중', value: ward.occupied },
      { name: '가용', value: ward.available },
      { name: '청소 중', value: ward.cleaning },
    ];

    return (
      <ResponsiveContainer width="100%" height={200}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div className="content-container bed-monitoring">
      <div className="notifications-section">
        <h3 className="section-title">실시간 알림</h3>
        <Notifications notifications={notifications} />
      </div>

      <div className="ward-overview">
        <h3 className="section-title">병동 개요</h3>
        <select onChange={(e) => setSelectedWard(e.target.value)} value={selectedWard}>
          <option value="all">전체 병동</option>
          {wardsData.map(ward => (
            <option key={ward.id} value={ward.id}>{ward.name}</option>
          ))}
        </select>
        <div className={`ward-charts ${selectedWard !== 'all' ? 'single-chart' : ''}`}>
          {selectedWard === 'all' ? (
            wardsData.map(ward => (
              <div key={ward.id} className="ward-chart">
                <h4>{ward.name}</h4>
                {renderWardChart(ward)}
              </div>
            ))
          ) : (
            <div className="ward-chart">
              <h4>{wardsData.find(w => w.id === parseInt(selectedWard)).name}</h4>
              {renderWardChart(wardsData.find(w => w.id === parseInt(selectedWard)))}
            </div>
          )}
        </div>
      </div>

      <div className="bed-details">
        <h3 className="section-title">병상 상세 정보</h3>
        <table>
          <thead>
            <tr>
              <th>병상 번호</th>
              <th>상태</th>
              <th>환자</th>
              <th>입원일</th>
              <th>예상 퇴원일</th>
            </tr>
          </thead>
          <tbody>
            {bedDetails
              .filter(bed => selectedWard === 'all' || bed.wardId === parseInt(selectedWard))
              .map(bed => (
                <tr key={bed.id} className={bed.status}>
                  <td>{bed.number}</td>
                  <td>{getStatusInKorean(bed.status)}</td>
                  <td>{bed.patient || '-'}</td>
                  <td>{bed.admissionDate || '-'}</td>
                  <td>{bed.expectedDischargeDate || '-'}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BedMonitoring;