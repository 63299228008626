import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';

const RealTimeStatus = ({ filter }) => {
  // 이 데이터는 실제로는 서버에서 가져와야 합니다
  const data = {
    all: [
      { name: '사용 중', value: 195 },
      { name: '가용', value: 75 },
      { name: '청소 중', value: 10 }
    ],
    department: [
      { name: '내과', value: 80 },
      { name: '외과', value: 60 },
      { name: '소아과', value: 30 },
      { name: '산부인과', value: 25 }
    ],
    gender: [
      { name: '남성', value: 100 },
      { name: '여성', value: 95 }
    ],
    roomType: [
      { name: '일반실', value: 150 },
      { name: '중환자실', value: 20 },
      { name: '격리실', value: 25 }
    ]
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

  return (
    <div className="real-time-status">
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data[filter]}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
            label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
          >
            {data[filter].map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RealTimeStatus;