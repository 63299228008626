import React, { useState } from 'react';
import Dashboard from './components/Dashboard';
import AdmissionForm from './components/AdmissionForm';
import BedMonitoring from './components/BedMonitoring';
import './App.css';

const App = () => {
  const [activeTab, setActiveTab] = useState('dashboard');

  return (
    <div className="app">
      <header className="app-header">
        <h1>Bed Assignment System</h1>
        <nav>
          <button onClick={() => setActiveTab('dashboard')} className={activeTab === 'dashboard' ? 'active' : ''}>대시보드</button>
          <button onClick={() => setActiveTab('admission')} className={activeTab === 'admission' ? 'active' : ''}>입원 신청</button>
          <button onClick={() => setActiveTab('monitoring')} className={activeTab === 'monitoring' ? 'active' : ''}>병상 모니터링</button>
        </nav>
      </header>
      <main className="app-main">
        {activeTab === 'dashboard' && <Dashboard />}
        {activeTab === 'admission' && <AdmissionForm />}
        {activeTab === 'monitoring' && <BedMonitoring />}
      </main>
    </div>
  );
};

export default App;