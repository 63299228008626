import React, { useState } from 'react';
import '../styles/admissionForm.css';

const AdmissionForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    age: '',
    gender: '',
    department: '',
    diagnosis: '',
    needIsolation: false,
    sharedRoom: false,
    preferredDepartment: ''
  });

  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('입원 신청:', formData);
    alert('입원 신청이 완료되었습니다.');
    setFormData({
      name: '',
      age: '',
      gender: '',
      department: '',
      diagnosis: '',
      needIsolation: false,
      sharedRoom: false,
      preferredDepartment: ''
    });
  };

  return (
    <div className="content-container admission-form">
      <div className="form-section">
        <h3 className="section-title">환자 정보 입력</h3>
        <form onSubmit={handleSubmit} className="form-grid">
          <div className="form-group">
            <label htmlFor="name">이름</label>
            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="age">나이</label>
            <input type="number" id="age" name="age" value={formData.age} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="gender">성별</label>
            <select id="gender" name="gender" value={formData.gender} onChange={handleChange} required>
              <option value="">선택하세요</option>
              <option value="male">남성</option>
              <option value="female">여성</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="department">진료과</label>
            <select id="department" name="department" value={formData.department} onChange={handleChange} required>
              <option value="">선택하세요</option>
              <option value="내과">내과</option>
              <option value="외과">외과</option>
              <option value="소아과">소아과</option>
              <option value="산부인과">산부인과</option>
            </select>
          </div>
          <div className="form-group full-width">
            <label htmlFor="diagnosis">진단</label>
            <textarea id="diagnosis" name="diagnosis" value={formData.diagnosis} onChange={handleChange} required />
          </div>
          <div className="form-group checkbox-group">
            <label>
              <input
                type="checkbox"
                name="needIsolation"
                checked={formData.needIsolation}
                onChange={handleChange}
              />
              격리병실 필요
            </label>
          </div>
          <div className="form-group checkbox-group">
            <label>
              <input
                type="checkbox"
                name="sharedRoom"
                checked={formData.sharedRoom}
                onChange={handleChange}
              />
              동일 성별 환자와 병실 공유 가능
            </label>
          </div>
          <div className="form-group full-width">
            <label htmlFor="preferredDepartment">희망 진료과 (선택사항)</label>
            <input
              type="text"
              id="preferredDepartment"
              name="preferredDepartment"
              value={formData.preferredDepartment}
              onChange={handleChange}
              placeholder="특정 진료과를 희망하시면 작성해주세요"
            />
          </div>
          <div className="form-group full-width">
            <button type="submit" className="submit-button">입원 신청</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdmissionForm;