import React, { useState } from 'react';
import RealTimeStatus from './RealTimeStatus';
import StatisticsDashboard from './StatisticsDashboard';
import '../styles/dashboard.css';

const Dashboard = () => {
  const [selectedFilter, setSelectedFilter] = useState('all');

  const summaryData = {
    totalBeds: 270,
    occupiedBeds: 195,
    availableBeds: 75,
    cleaningBeds: 10,
    occupancyRate: (195 / 270 * 100).toFixed(1)
  };

  return (
    <div className="content-container dashboard">
      <h3 className="section-title">병상 총괄 현황</h3>
      
      <div className="summary-cards">
        <div className="card">
          <h3>전체 병상</h3>
          <p className="large-number">{summaryData.totalBeds}</p>
        </div>
        <div className="card">
          <h3>사용 중인 병상</h3>
          <p className="large-number">{summaryData.occupiedBeds}</p>
        </div>
        <div className="card">
          <h3>가용 병상</h3>
          <p className="large-number">{summaryData.availableBeds}</p>
        </div>
        <div className="card">
          <h3>청소 중인 병상</h3>
          <p className="large-number">{summaryData.cleaningBeds}</p>
        </div>
        <div className="card">
          <h3>병실 가동률</h3>
          <p className="large-number">{summaryData.occupancyRate}%</p>
        </div>
      </div>

      <div className="dashboard-section">
        <h3 className="section-title">유형별 현황</h3>
        <div className="filter-controls">
          <select onChange={(e) => setSelectedFilter(e.target.value)} value={selectedFilter}>
            <option value="all">전체</option>
            <option value="department">진료과별</option>
            <option value="gender">성별</option>
            <option value="roomType">병실 유형별</option>
          </select>
        </div>
        <RealTimeStatus filter={selectedFilter} />
      </div>

      <div className="dashboard-section">
        <h3 className="section-title">통계 (일/주/월별)</h3>
        <StatisticsDashboard />
      </div>
    </div>
  );
};

export default Dashboard;