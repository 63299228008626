import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';

const StatisticsDashboard = () => {
  const [timeFrame, setTimeFrame] = useState('daily');

  // 이 데이터는 실제로는 서버에서 가져와야 합니다
  const data = {
    daily: [
      { name: '6/1', 내과: 40, 외과: 30, 소아과: 20, 산부인과: 15 },
      { name: '6/2', 내과: 45, 외과: 35, 소아과: 18, 산부인과: 20 },
      { name: '6/3', 내과: 42, 외과: 32, 소아과: 22, 산부인과: 18 },
      { name: '6/4', 내과: 48, 외과: 28, 소아과: 25, 산부인과: 16 },
      { name: '6/5', 내과: 50, 외과: 33, 소아과: 19, 산부인과: 22 },
      { name: '6/6', 내과: 47, 외과: 31, 소아과: 23, 산부인과: 19 },
      { name: '6/7', 내과: 44, 외과: 34, 소아과: 21, 산부인과: 17 },
    ],
    weekly: [
      { name: '1주차', 내과: 280, 외과: 220, 소아과: 150, 산부인과: 120 },
      { name: '2주차', 내과: 300, 외과: 240, 소아과: 140, 산부인과: 130 },
      { name: '3주차', 내과: 290, 외과: 230, 소아과: 160, 산부인과: 125 },
      { name: '4주차', 내과: 310, 외과: 250, 소아과: 145, 산부인과: 135 },
    ],
    monthly: [
      { name: '1월', 가동률: 75 },
      { name: '2월', 가동률: 78 },
      { name: '3월', 가동률: 80 },
      { name: '4월', 가동률: 82 },
      { name: '5월', 가동률: 79 },
      { name: '6월', 가동률: 81 },
    ]
  };

  return (
    <div className="statistics-dashboard">
      <div className="filter-controls">
        <select onChange={(e) => setTimeFrame(e.target.value)} value={timeFrame}>
          <option value="daily">일별</option>
          <option value="weekly">주별</option>
          <option value="monthly">월별</option>
        </select>
      </div>

      {timeFrame !== 'monthly' ? (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data[timeFrame]}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="내과" fill="#8884d8" />
            <Bar dataKey="외과" fill="#82ca9d" />
            <Bar dataKey="소아과" fill="#ffc658" />
            <Bar dataKey="산부인과" fill="#ff8042" />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data.monthly}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="가동률" stroke="#8884d8" />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default StatisticsDashboard;